import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "styled-components";
import Layout from "./layout";
import SEO from "./seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";
import type { DataSourcePageQuery } from "../../graphql-types";

interface Props {
  data: DataSourcePageQuery;
}

const DataSourcePage: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO
        title={data?.mdx?.frontmatter?.displayName || "Data source"}
        description={data?.mdx?.frontmatter?.displayName || "Data source"}
      />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <MDXRenderer>{data?.mdx?.body || ""}</MDXRenderer>
          <div>
            <p>
              Trouble loading data? Contact our support team by sending an email
              to <a href="mailto:support@fastrrr.com">support@fastrrr.com</a>{" "}
              and we&apos;ll be glad to help.
              <br />
              Fastrrr is a unique and innovative performance analysis solution
              based on AI for sailors and their coaches.
            </p>
          </div>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export const data = graphql`
  query DataSourcePage($slug: String) {
    mdx(slug: { eq: $slug }, fields: { source: { eq: "data-sources" } }) {
      body
      frontmatter {
        displayName
      }
    }
  }
`;

export default DataSourcePage;

const Wrapper = styled.div`
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;
